/* Set column widths for assigned host accordion tables in reassign license step*/
.reassign-panelbar-grid table thead tr th:nth-of-type(1){
    width: 11.5%
}
.reassign-panelbar-grid table tbody tr td:nth-of-type(1){
    width: 11.5%
}

.reassign-panelbar-grid table thead tr th:nth-of-type(3){
    width: 25%
}
.reassign-panelbar-grid table tbody tr td:nth-of-type(3){
    width: 25%
}

.reassign-panelbar-grid table thead tr th:nth-of-type(4){
    width: 5.5%
}
.reassign-panelbar-grid table tbody tr td:nth-of-type(4){
    width: 5.5%
}

.reassign-panelbar-grid table thead tr th:nth-of-type(5){
    width: 13.5%
}
.reassign-panelbar-grid table tbody tr td:nth-of-type(5){
    width: 13.5%
}

.reassign-panelbar-grid table thead tr th:nth-of-type(6){
    width: 4%
}
.reassign-panelbar-grid table tbody tr td:nth-of-type(6){
    width: 4%
}

.reassign-panelbar-grid table thead tr th:nth-of-type(7){
    width: 6%
}
.reassign-panelbar-grid table tbody tr td:nth-of-type(7){
    width: 6%
}

/** Used to set default cursor for Grid cols that cant be sorted **/
.reassign-panelbar-grid table thead tr th:nth-child(1) .k-cell-inner .k-link,
.reassign-panelbar-grid table thead tr th:nth-child(2) .k-cell-inner .k-link,
.reassign-panelbar-grid table thead tr th:nth-child(3) .k-cell-inner .k-link,
.reassign-panelbar-grid table thead tr th:nth-child(4) .k-cell-inner .k-link,
.reassign-panelbar-grid table thead tr th:nth-child(5) .k-cell-inner .k-link,
.reassign-panelbar-grid table thead tr th:nth-child(6) .k-cell-inner .k-link,
.reassign-panelbar-grid table thead tr th:nth-child(7) .k-cell-inner .k-link {
    cursor: default;
}

/* Set column widths for reassign modal tables in reassign license step*/
.reassign-modal-grid table thead tr th:nth-of-type(1){
    width: 16%
}
.reassign-modal-grid table tbody tr td:nth-of-type(1){
    width: 16%
}

.reassign-modal-grid table thead tr th:nth-of-type(3){
    width: 8%
}
.reassign-modal-grid table tbody tr td:nth-of-type(3){
    width: 8%
}

.reassign-modal-grid table thead tr th:nth-of-type(4){
    width: 20.5%
}
.reassign-modal-grid table tbody tr td:nth-of-type(4){
    width: 20.5%
}

.reassign-modal-grid table thead tr th:nth-of-type(5){
    width: 9%
}
.reassign-modal-grid table tbody tr td:nth-of-type(5){
    width: 9%
}

/** Used to set default cursor for Grid cols that cant be sorted **/
.reassign-modal-grid table thead tr th:nth-child(1) .k-cell-inner .k-link,
.reassign-modal-grid table thead tr th:nth-child(2) .k-cell-inner .k-link,
.reassign-modal-grid table thead tr th:nth-child(3) .k-cell-inner .k-link,
.reassign-modal-grid table thead tr th:nth-child(4) .k-cell-inner .k-link,
.reassign-modal-grid table thead tr th:nth-child(5) .k-cell-inner .k-link {
    cursor: default;
}

/* Set column widths for accordion tables in select license step*/
.select-panelbar-grid table thead tr th:nth-of-type(1){
    width: 2%
}
.select-panelbar-grid table tbody tr td:nth-of-type(1){
    width: 2%
}

.select-panelbar-grid table thead tr th:nth-of-type(2){
    width: 11.5%
}
.select-panelbar-grid table tbody tr td:nth-of-type(2){
    width: 11.5%
}

.select-panelbar-grid table thead tr th:nth-of-type(4){
    width: 25%
}
.select-panelbar-grid table tbody tr td:nth-of-type(4){
    width: 25%
}

.select-panelbar-grid table thead tr th:nth-of-type(5){
    width: 5.5%
}
.select-panelbar-grid table tbody tr td:nth-of-type(5){
    width: 5.5%
}

.select-panelbar-grid table thead tr th:nth-of-type(6){
    width: 13.5%
}
.select-panelbar-grid table tbody tr td:nth-of-type(6){
    width: 13.5%
}

.select-panelbar-grid table thead tr th:nth-of-type(7){
    width: 4%
}
.select-panelbar-grid table tbody tr td:nth-of-type(7){
    width: 4%
}

/** Used to set default cursor for Grid cols that cant be sorted **/
.select-panelbar-grid table thead tr th:nth-child(1) .k-cell-inner .k-link,
.select-panelbar-grid table thead tr th:nth-child(2) .k-cell-inner .k-link,
.select-panelbar-grid table thead tr th:nth-child(3) .k-cell-inner .k-link,
.select-panelbar-grid table thead tr th:nth-child(4) .k-cell-inner .k-link,
.select-panelbar-grid table thead tr th:nth-child(5) .k-cell-inner .k-link,
.select-panelbar-grid table thead tr th:nth-child(6) .k-cell-inner .k-link,
.select-panelbar-grid table thead tr th:nth-child(7) .k-cell-inner .k-link {
    cursor: default;
}

/* Set column widths for assigned host accordion tables in reassign license step*/
.download-panelbar-grid table thead tr th:nth-of-type(1){
    width: 11.5%
}
.download-panelbar-grid table tbody tr td:nth-of-type(1){
    width: 11.5%
}

.download-panelbar-grid table thead tr th:nth-of-type(3){
    width: 25%
}
.download-panelbar-grid table tbody tr td:nth-of-type(3){
    width: 25%
}

.download-panelbar-grid table thead tr th:nth-of-type(4){
    width: 5.5%
}
.download-panelbar-grid table tbody tr td:nth-of-type(4){
    width: 5.5%
}

.download-panelbar-grid table thead tr th:nth-of-type(5){
    width: 13.5%
}
.download-panelbar-grid table tbody tr td:nth-of-type(5){
    width: 13.5%
}

.download-panelbar-grid table thead tr th:nth-of-type(6){
    width: 3.5%
}
.download-panelbar-grid table tbody tr td:nth-of-type(6){
    width: 3.5%
}


/** Used to set default cursor for Grid cols that cant be sorted **/
.download-panelbar-grid table thead tr th:nth-child(1) .k-cell-inner .k-link,
.download-panelbar-grid table thead tr th:nth-child(2) .k-cell-inner .k-link,
.download-panelbar-grid table thead tr th:nth-child(3) .k-cell-inner .k-link,
.download-panelbar-grid table thead tr th:nth-child(4) .k-cell-inner .k-link,
.download-panelbar-grid table thead tr th:nth-child(5) .k-cell-inner .k-link,
.download-panelbar-grid table thead tr th:nth-child(6) .k-cell-inner .k-link {
    cursor: default;
}