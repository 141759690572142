.my-search-product-grid table thead tr th:nth-child(3) .k-cell-inner .k-link,
.my-search-product-grid table thead tr th:nth-child(4) .k-cell-inner .k-link,
.my-search-product-grid table thead tr th:nth-child(5) .k-cell-inner .k-link,
.my-search-product-grid table thead tr th:nth-child(6) .k-cell-inner .k-link {
    cursor: default
}

.my-search-product-grid table thead tr th:nth-of-type(1){
    width: 13%
}
.my-search-product-grid table tbody tr td:nth-of-type(1){
    width: 13%
}

.my-search-product-grid table thead tr th:nth-of-type(3){
    width: 6%
}
.my-search-product-grid table tbody tr td:nth-of-type(3){
    width: 6%
}

.my-search-product-grid table thead tr th:nth-of-type(4){
    width: 13.5%
}
.my-search-product-grid table tbody tr td:nth-of-type(4){
    width: 13.5%
}

.my-search-product-grid table thead tr th:nth-of-type(5){
    width: 10%
}
.my-search-product-grid table tbody tr td:nth-of-type(5){
    width: 10%
}

.my-search-product-grid table thead tr th:nth-of-type(6){
    width: 10%
}
.my-search-product-grid table tbody tr td:nth-of-type(6){
    width: 10%
}

.my-search-product-grid table thead tr th:nth-of-type(7){
    width: 3%
}
.my-search-product-grid table tbody tr td:nth-of-type(7){
    width: 3%
}

.my-selected-grid table thead tr th:nth-child(3) .k-cell-inner .k-link,
.my-selected-grid table thead tr th:nth-child(4) .k-cell-inner .k-link,
.my-selected-grid table thead tr th:nth-child(5) .k-cell-inner .k-link,
.my-selected-grid table thead tr th:nth-child(6) .k-cell-inner .k-link,
.my-selected-grid table thead tr th:nth-child(7) .k-cell-inner .k-link,
.my-selected-grid table thead tr th:nth-child(8) .k-cell-inner .k-link
{
    cursor: default
}

.my-selected-grid table thead tr th:nth-of-type(1){
    width: 13%
}
.my-selected-grid table tbody tr td:nth-of-type(1){
    width: 13%
}

.my-selected-grid table thead tr th:nth-of-type(3){
    width: 6%
}
.my-selected-grid table tbody tr td:nth-of-type(3){
    width: 6%
}

.my-selected-grid table thead tr th:nth-of-type(4){
    width: 13.5%
}
.my-selected-grid table tbody tr td:nth-of-type(4){
    width: 13.5%
}

.my-selected-grid table thead tr th:nth-of-type(5){
    width: 10%
}
.my-selected-grid table tbody tr td:nth-of-type(5){
    width: 10%
}

.my-selected-grid table thead tr th:nth-of-type(6){
    width: 10%
}
.my-selected-grid table tbody tr td:nth-of-type(6){
    width: 10%
}

.my-selected-grid table thead tr th:nth-of-type(7){
    width: 10%
}
.my-selected-grid table tbody tr td:nth-of-type(7){
    width: 10%
}

.my-selected-grid table thead tr th:nth-of-type(8){
    width: 3%
}
.my-selected-grid table tbody tr td:nth-of-type(8){
    width: 3%
}

.delete-grid table thead tr th:nth-child(1) .k-cell-inner .k-link,
.delete-grid table thead tr th:nth-child(2) .k-cell-inner .k-link,
.delete-grid table thead tr th:nth-child(3) .k-cell-inner .k-link,
.delete-grid table thead tr th:nth-child(4) .k-cell-inner .k-link,
.delete-grid table thead tr th:nth-child(5) .k-cell-inner .k-link,
.delete-grid table thead tr th:nth-child(6) .k-cell-inner .k-link,
.delete-grid table thead tr th:nth-child(7) .k-cell-inner .k-link
{
    cursor: default
}

.delete-grid table thead tr th:nth-of-type(1){
    width: 13%
}
.delete-grid table tbody tr td:nth-of-type(1){
    width: 13%
}

.delete-grid table thead tr th:nth-of-type(3){
    width: 4%
}
.delete-grid table tbody tr td:nth-of-type(3){
    width: 4%
}

.delete-grid table thead tr th:nth-of-type(4){
    width: 10%
}
.delete-grid table tbody tr td:nth-of-type(4){
    width: 10%
}

.delete-grid table thead tr th:nth-of-type(5){
    width: 10%
}
.delete-grid table tbody tr td:nth-of-type(5){
    width: 10%
}

.delete-grid table thead tr th:nth-of-type(6){
    width: 8%
}
.delete-grid table tbody tr td:nth-of-type(6){
    width: 8%
}
.ea-assign-products-panelbar-grid table thead tr th:nth-child(7) .k-cell-inner .k-link,
.my-products-grid table thead tr th:nth-child(5) .k-cell-inner .k-link,
.my-products-grid table thead tr th:nth-child(6) .k-cell-inner .k-link,
.my-products-grid table thead tr th:nth-child(7) .k-cell-inner .k-link,
.my-products-grid table thead tr th:nth-child(8) .k-cell-inner .k-link {
    cursor: default
}

.my-products-grid table thead tr th:nth-of-type(1){
    text-align: center;
    vertical-align: middle;
}
.my-products-grid table tbody tr td:nth-of-type(1){
    text-align: center;
    vertical-align: middle;
}

.my-products-grid table thead tr th:nth-of-type(2){
    width: 13%;
    vertical-align: middle;
}
.my-products-grid table tbody tr td:nth-of-type(2){
    width: 13%;
    vertical-align: middle;
}

.my-products-grid table thead tr th:nth-of-type(3){
    vertical-align: middle;
}
.my-products-grid table tbody tr td:nth-of-type(3){
    vertical-align: middle;
}

.my-products-grid table thead tr th:nth-of-type(4){
    width: 3%;
    vertical-align: middle;
}
.my-products-grid table tbody tr td:nth-of-type(4){
    width: 3%;
    vertical-align: middle;
}

.my-products-grid table thead tr th:nth-of-type(5){
    width: 6.5%;
    vertical-align: middle;
}
.my-products-grid table tbody tr td:nth-of-type(5){
    width: 6.5%;
    vertical-align: middle;
}

.my-products-grid table thead tr th:nth-of-type(6){
    width: 13%;
    vertical-align: middle;
}
.my-products-grid table tbody tr td:nth-of-type(6){
    width: 13%;
    vertical-align: middle;
}

.my-products-grid table thead tr th:nth-of-type(7){
    width: 13%;
    vertical-align: middle;
}
.my-products-grid table tbody tr td:nth-of-type(7){
    width: 13%;
    vertical-align: middle;
}

.my-products-grid table thead tr th:nth-of-type(8){
    width: 7%;
    vertical-align: middle;
}
.my-products-grid table tbody tr td:nth-of-type(8){
    width: 7%;
    vertical-align: middle;
}

.my-products-grid table tbody tr td span.k-input.k-datepicker.k-invalid {
    border-color: inherit !important;
}

.my-products-end-date-popover .k-popover div, table, colgroup {
    box-sizing: content-box;
}

.request-download-grid table thead tr th:nth-child(1) .k-cell-inner .k-link,
.request-download-grid table thead tr th:nth-child(2) .k-cell-inner .k-link,
.request-download-grid table thead tr th:nth-child(3) .k-cell-inner .k-link,
.request-download-grid table thead tr th:nth-child(4) .k-cell-inner .k-link,
.request-download-grid table thead tr th:nth-child(5) .k-cell-inner .k-link,
.request-download-grid table thead tr th:nth-child(6) .k-cell-inner .k-link,
.request-download-grid table thead tr th:nth-child(7) .k-cell-inner .k-link
{
    cursor: default
}

.request-download-grid table thead tr th:nth-of-type(1){
    width: 13%
}
.request-download-grid table tbody tr td:nth-of-type(1){
    width: 13%
}

.request-download-grid table thead tr th:nth-of-type(3){
    width: 5%
}
.request-download-grid table tbody tr td:nth-of-type(3){
    width: 5%
}

.request-download-grid table thead tr th:nth-of-type(4){
    width: 7%
}
.request-download-grid table tbody tr td:nth-of-type(4){
    width: 7%
}

.request-download-grid table thead tr th:nth-of-type(5){
    width: 13.5%
}
.request-download-grid table tbody tr td:nth-of-type(5){
    width: 13.5%
}

.request-download-grid table thead tr th:nth-of-type(6){
    width: 13.5%
}
.request-download-grid table tbody tr td:nth-of-type(6){
    width: 13.5%
}

.request-download-grid table thead tr th:nth-of-type(7){
    width: 13.5%
}
.request-download-grid table tbody tr td:nth-of-type(7){
    width: 13.5%
}