
.chart_container {
    height: 11.25rem;
    width: 11.25rem;
    padding: 0.5rem;
}

.label-container > span:first-child {
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    color: #4E5969;
}

.label-container > span:nth-child(2) {
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    color: #4E5969;
}

.label-container > span:last-child {
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    color: #4E5969;
}

.chart-header > span:first-child {
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    text-align: right;
    color: #333333;
}

.chart-header > span:nth-child(2) {
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    text-align: right;
    color: #333333;
}

.chart-header > span:last-child {
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    margin-top: 0.375rem;
    color: #333333;
}