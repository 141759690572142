.group-host-panelbar-grid table thead tr th:nth-child(1) .k-cell-inner .k-link,
.group-host-panelbar-grid table thead tr th:nth-child(2) .k-cell-inner .k-link,
.group-host-panelbar-grid table thead tr th:nth-child(3) .k-cell-inner .k-link,
.group-host-panelbar-grid table thead tr th:nth-child(4) .k-cell-inner .k-link,
.group-host-panelbar-grid table thead tr th:nth-child(5) .k-cell-inner .k-link,
.group-host-panelbar-grid table thead tr th:nth-child(6) .k-cell-inner .k-link,
.group-host-panelbar-grid table thead tr th:nth-child(7) .k-cell-inner .k-link,
.group-host-panelbar-grid table thead tr th:nth-child(8) .k-cell-inner .k-link {
    cursor: default
}

.group-host-panelbar-grid table thead tr th:nth-of-type(1){
    width: 11.5%
}

.group-host-panelbar-grid table tbody tr td:nth-of-type(1){
    width: 11.5%
}

.group-host-panelbar-grid table thead tr th:nth-of-type(3){
    width: 4%
}

.group-host-panelbar-grid table tbody tr td:nth-of-type(3){
    width: 4%
}

.group-host-panelbar-grid table thead tr th:nth-of-type(4){
    width: 12.5%
}
.group-host-panelbar-grid table tbody tr td:nth-of-type(4){
    width: 12.5%
}

.group-host-panelbar-grid table thead tr th:nth-of-type(5){
    width: 12.5%
}
.group-host-panelbar-grid table tbody tr td:nth-of-type(5){
    width: 12.5%
}

.group-host-panelbar-grid table thead tr th:nth-of-type(6){
    width: 8%
}
.group-host-panelbar-grid table tbody tr td:nth-of-type(6){
    width: 8%
}

.group-host-panelbar-grid table thead tr th:nth-of-type(7){
    width: 8%
}
.group-host-panelbar-grid table tbody tr td:nth-of-type(7){
    width: 8%
}

.group-host-panelbar-grid table thead tr th:nth-of-type(8){
    width: 6.5%
}
.group-host-panelbar-grid table tbody tr td:nth-of-type(8){
    width: 6.5%
}