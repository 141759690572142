/** Used to set default cursor for Grid cols that cant be sorted **/
.view-cert-order-grid table thead tr th:nth-child(4) .k-cell-inner .k-link,
.view-cert-order-grid table thead tr th:nth-child(5) .k-cell-inner .k-link,
.view-cert-order-grid table thead tr th:nth-child(6) .k-cell-inner .k-link,
.view-cert-order-grid table thead tr th:nth-child(7) .k-cell-inner .k-link,
.view-cert-order-grid table thead tr th:nth-child(8) .k-cell-inner .k-link {
    cursor: default;
}

.view-cert-order-grid table thead tr th:nth-of-type(5) {
    width: 15%;
}

.view-cert-order-grid table thead tr th:nth-of-type(6) {
    width: 15%;
}

.view-cert-order-grid table thead tr th:nth-of-type(7) {
    width: 8%;
}

.view-cert-order-grid table thead tr th:nth-of-type(8) {
    width: 8%;
}