.enter-transport-ccl-grid table thead tr th:nth-child(1) .k-cell-inner .k-link,
.enter-transport-ccl-grid table thead tr th:nth-child(2) .k-cell-inner .k-link,
.enter-transport-ccl-grid table thead tr th:nth-child(3) .k-cell-inner .k-link,
.enter-transport-ccl-grid table thead tr th:nth-child(4) .k-cell-inner .k-link,
.enter-transport-ccl-grid table thead tr th:nth-child(5) .k-cell-inner .k-link,
.enter-transport-ccl-grid table thead tr th:nth-child(6) .k-cell-inner .k-link {
    cursor: default
}

.enter-transport-ccl-grid table thead tr th:nth-of-type(1){
    width: 15%
}

.enter-transport-ccl-grid table tbody tr td:nth-of-type(1){
    width: 15%
}

.enter-transport-ccl-grid table thead tr th:nth-of-type(2){
    width: 10%
}

.enter-transport-ccl-grid table tbody tr td:nth-of-type(2){
    width: 10%
}

.enter-transport-ccl-grid table thead tr th:nth-of-type(3){
    width: 15%
}

.enter-transport-ccl-grid table tbody tr td:nth-of-type(3){
    width: 15%
}

.enter-transport-ccl-grid table thead tr th:nth-of-type(5){
    width: 5%
}

.enter-transport-ccl-grid table tbody tr td:nth-of-type(5){
    width: 5%
}

.enter-transport-ccl-grid table thead tr th:nth-of-type(6){
    width: 8%
}

.enter-transport-ccl-grid table tbody tr td:nth-of-type(6){
    width: 8%
}


.review-submit-ccl-gird table thead tr th:nth-child(1) .k-cell-inner .k-link,
.review-submit-ccl-gird table thead tr th:nth-child(2) .k-cell-inner .k-link,
.review-submit-ccl-gird table thead tr th:nth-child(3) .k-cell-inner .k-link,
.review-submit-ccl-gird table thead tr th:nth-child(4) .k-cell-inner .k-link {
    cursor: default
}


.review-submit-ccl-gird table thead tr th:nth-of-type(1){
    width: 15%
}

.review-submit-ccl-gird table tbody tr td:nth-of-type(1){
    width: 15%
}

.review-submit-ccl-gird table thead tr th:nth-of-type(2){
    width: 10%
}

.review-submit-ccl-gird table tbody tr td:nth-of-type(2){
    width: 10%
}

.review-submit-ccl-gird table thead tr th:nth-of-type(4){
    width: 5%
}

.review-submit-ccl-gird table tbody tr td:nth-of-type(4){
    width: 5%
}

