/*
        This font software is the property of Commercial Type.

        You may not modify the font software, use it on another website, or install it on a computer.

        License information is available at http://commercialtype.com/eula
        For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

        Copyright (C) 2022 Schwartzco Inc.
        License: 2204-CADRPN     
*/


@font-face {
  font-family: 'Graphik LG Web';
  src: url('graphik/Graphik-SuperItalic-Gr-Web.woff2') format('woff2'),
       url('graphik/Graphik-SuperItalic-Gr-Web.woff') format('woff');
  font-weight:  900;
  font-style:   italic;
  font-stretch: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Graphik LG Web';
  src: url('graphik/Graphik-Super-Gr-Web.woff2') format('woff2'),
       url('graphik/Graphik-Super-Gr-Web.woff') format('woff');
  font-weight:  900;
  font-style:   normal;
  font-stretch: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Graphik LG Web';
  src: url('graphik/Graphik-BlackItalic-Gr-Web.woff2') format('woff2'),
       url('graphik/Graphik-BlackItalic-Gr-Web.woff') format('woff');
  font-weight:  800;
  font-style:   italic;
  font-stretch: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Graphik LG Web';
  src: url('graphik/Graphik-Black-Gr-Web.woff2') format('woff2'),
       url('graphik/Graphik-Black-Gr-Web.woff') format('woff');
  font-weight:  800;
  font-style:   normal;
  font-stretch: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Graphik LG Web';
  src: url('graphik/Graphik-BoldItalic-Gr-Web.woff2') format('woff2'),
       url('graphik/Graphik-BoldItalic-Gr-Web.woff') format('woff');
  font-weight:  700;
  font-style:   italic;
  font-stretch: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Graphik LG Web';
  src: url('graphik/Graphik-Bold-Gr-Web.woff2') format('woff2'),
       url('graphik/Graphik-Bold-Gr-Web.woff') format('woff');
  font-weight:  700;
  font-style:   normal;
  font-stretch: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Graphik LG Web';
  src: url('graphik/Graphik-SemiboldItalic-Gr-Web.woff2') format('woff2'),
       url('graphik/Graphik-SemiboldItalic-Gr-Web.woff') format('woff');
  font-weight:  600;
  font-style:   italic;
  font-stretch: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Graphik LG Web';
  src: url('graphik/Graphik-Semibold-Gr-Web.woff2') format('woff2'),
       url('graphik/Graphik-Semibold-Gr-Web.woff') format('woff');
  font-weight:  600;
  font-style:   normal;
  font-stretch: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Graphik LG Web';
  src: url('graphik/Graphik-MediumItalic-Gr-Web.woff2') format('woff2'),
       url('graphik/Graphik-MediumItalic-Gr-Web.woff') format('woff');
  font-weight:  500;
  font-style:   italic;
  font-stretch: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Graphik LG Web';
  src: url('graphik/Graphik-Medium-Gr-Web.woff2') format('woff2'),
       url('graphik/Graphik-Medium-Gr-Web.woff') format('woff');
  font-weight:  500;
  font-style:   normal;
  font-stretch: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Graphik LG Web';
  src: url('graphik/Graphik-RegularItalic-Gr-Web.woff2') format('woff2'),
       url('graphik/Graphik-RegularItalic-Gr-Web.woff') format('woff');
  font-weight:  400;
  font-style:   italic;
  font-stretch: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Graphik LG Web';
  src: url('graphik/Graphik-Regular-Gr-Web.woff2') format('woff2'),
       url('graphik/Graphik-Regular-Gr-Web.woff') format('woff');
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Graphik LG Web';
  src: url('graphik/Graphik-LightItalic-Gr-Web.woff2') format('woff2'),
       url('graphik/Graphik-LightItalic-Gr-Web.woff') format('woff');
  font-weight:  300;
  font-style:   italic;
  font-stretch: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Graphik LG Web';
  src: url('graphik/Graphik-Light-Gr-Web.woff2') format('woff2'),
       url('graphik/Graphik-Light-Gr-Web.woff') format('woff');
  font-weight:  300;
  font-style:   normal;
  font-stretch: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Graphik LG Web';
  src: url('graphik/Graphik-ExtralightItalic-Gr-Web.woff2') format('woff2'),
       url('graphik/Graphik-ExtralightItalic-Gr-Web.woff') format('woff');
  font-weight:  200;
  font-style:   italic;
  font-stretch: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Graphik LG Web';
  src: url('graphik/Graphik-Extralight-Gr-Web.woff2') format('woff2'),
       url('graphik/Graphik-Extralight-Gr-Web.woff') format('woff');
  font-weight:  200;
  font-style:   normal;
  font-stretch: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Graphik LG Web';
  src: url('graphik/Graphik-ThinItalic-Gr-Web.woff2') format('woff2'),
       url('graphik/Graphik-ThinItalic-Gr-Web.woff') format('woff');
  font-weight:  100;
  font-style:   italic;
  font-stretch: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Graphik LG Web';
  src: url('graphik/Graphik-Thin-Gr-Web.woff2') format('woff2'),
       url('graphik/Graphik-Thin-Gr-Web.woff') format('woff');
  font-weight:  100;
  font-style:   normal;
  font-stretch: normal;
  font-display: auto;
}

html * {
    font-family: 'Graphik LG Web', serif;
}