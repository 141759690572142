.k-today {
    color: #424242 !important;
}

.request-again-detail {
    overflow-wrap: anywhere;
    align-self: center;
    margin-bottom: auto;
}


.request-again-modal-grid {
    width: 75vw;
}

.request-again-grid,
.request-again-modal-grid {
    padding: 0;
    overflow: auto;
}

.request-again-grid .k-grid-header .k-header,
.request-again-modal-grid .k-grid-header .k-header {
    vertical-align: middle;
}

.request-again-grid .k-column-title,
.request-again-modal-grid .k-column-title {
    white-space: nowrap !important;
}

.request-again-grid tr,
.request-again-modal-grid tr {
    white-space: nowrap;
}

/* Set column widths for request again grid */
.request-again-grid table thead tr th:nth-of-type(1) {
    width: 2%
}
.request-again-grid table tbody tr td:nth-of-type(1) {
    width: 2%
}

.request-again-grid table thead tr th:nth-of-type(2) {
    width: 12%
}
.request-again-grid table tbody tr td:nth-of-type(2) {
    width: 12%
}

.request-again-grid table thead tr th:nth-of-type(3) {
    width: 12%
}
.request-again-grid table tbody tr td:nth-of-type(3) {
    width: 12%
}

.request-again-grid table thead tr th:nth-of-type(4) {
    width: 22%
}
.request-again-grid table tbody tr td:nth-of-type(4) {
    width: 22%
}

.request-again-grid table thead tr th:nth-of-type(5) {
    width: 2%
}
.request-again-grid table tbody tr td:nth-of-type(5) {
    width: 2%
}

.request-again-grid table thead tr th:nth-of-type(6) {
    width: 8%
}
.request-again-grid table tbody tr td:nth-of-type(6) {
    width: 8%
}

.request-again-grid table thead tr th:nth-of-type(7) {
    width: 8%
}
.request-again-grid table tbody tr td:nth-of-type(7) {
    width: 8%
}

.request-again-grid table thead tr th:nth-of-type(8) {
    width: 4%
}
.request-again-grid table tbody tr td:nth-of-type(8) {
    width: 4%
}

.request-again-grid table thead tr th:nth-of-type(9) {
    width: 10%
}
.request-again-grid table tbody tr td:nth-of-type(9) {
    width: 10%
}

.request-again-grid table thead tr th:nth-of-type(10) {
    width: 10%
}
.request-again-grid table tbody tr td:nth-of-type(10) {
    width: 10%
}

.request-again-grid table thead tr th:nth-of-type(11) {
    width: 10%
}
.request-again-grid table tbody tr td:nth-of-type(11) {
    width: 10%
}

/* Set column widths for request again modal grid */
.request-again-modal-grid table thead tr th:nth-of-type(1) {
    width: 14%
}
.request-again-modal-grid table tbody tr td:nth-of-type(1) {
    width: 14%
}

.request-again-modal-grid table thead tr th:nth-of-type(2) {
    width: 14%
}
.request-again-modal-grid table tbody tr td:nth-of-type(2) {
    width: 14%
}

.request-again-modal-grid table thead tr th:nth-of-type(3) {
    width: 30%
}
.request-again-modal-grid table tbody tr td:nth-of-type(3) {
    width: 30%
}

.request-again-modal-grid table thead tr th:nth-of-type(4) {
    width: 2%
}
.request-again-modal-grid table tbody tr td:nth-of-type(4) {
    width: 2%
}

.request-again-modal-grid table thead tr th:nth-of-type(5) {
    width: 10%
}
.request-again-modal-grid table tbody tr td:nth-of-type(5) {
    width: 10%
}

.request-again-modal-grid table thead tr th:nth-of-type(6) {
    width: 10%
}
.request-again-modal-grid table tbody tr td:nth-of-type(6) {
    width: 10%
}

.request-again-modal-grid table thead tr th:nth-of-type(7) {
    width: 10%
}
.request-again-modal-grid table tbody tr td:nth-of-type(7) {
    width: 10%
}

.request-again-modal-grid table thead tr th:nth-of-type(8) {
    width: 10%
}
.request-again-modal-grid table tbody tr td:nth-of-type(8) {
    width: 10%
}