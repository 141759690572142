.order-number-certificate {
    color: var(--keysight-secondary) !important;
    font-weight: bold;
    cursor: pointer;
}

.expiring-license-supports-grid {
    overflow: auto;
    text-wrap: wrap;
    word-break: break-word;
}