.request-update-license-grid table thead tr th:nth-child(1) .k-cell-inner .k-link,
.request-update-license-grid table thead tr th:nth-child(2) .k-cell-inner .k-link,
.request-update-license-grid table thead tr th:nth-child(3) .k-cell-inner .k-link
.request-update-license-grid table thead tr th:nth-child(4) .k-cell-inner .k-link {
    cursor: default
}

.request-update-license-grid table thead tr th:nth-of-type(1){
    width: 15%
}

.request-update-license-grid table tbody tr td:nth-of-type(1){
    width: 15%
}

.request-update-license-grid table thead tr th:nth-of-type(3){
    width: 5.5%
}

.request-update-license-grid table tbody tr td:nth-of-type(3){
    width: 5.5%
}

.request-update-license-grid table thead tr th:nth-of-type(4){
    width: 8.5%
}
.request-update-license-grid table tbody tr td:nth-of-type(4){
    width: 8.5%
}
