.sa-checkin-hosts-grid {
    overflow-x: auto;
}
.sa-checkin-hosts-grid table thead tr th:nth-of-type(1){
    width: 1%
}
.sa-checkin-hosts-grid table tbody tr td:nth-of-type(1){
    width: 1%
}
.sa-checkin-hosts-grid table thead tr th:nth-of-type(3){
    width: 12%;
}
.sa-checkin-hosts-grid table tbody tr td:nth-of-type(3){
    width: 12%;
}
.sa-checkin-hosts-grid table thead tr th:nth-of-type(4){
    width: 12%;
}
.sa-checkin-hosts-grid table tbody tr td:nth-of-type(4){
    width: 12%;
}
.sa-checkin-hosts-grid table thead tr th:nth-of-type(5){
    width: 8%
}
.sa-checkin-hosts-grid table tbody tr td:nth-of-type(5){
    width: 8%
}
.sa-checkin-hosts-grid table thead tr th:nth-of-type(6){
    width: 8%
}
.sa-checkin-hosts-grid table tbody tr td:nth-of-type(6){
    width: 8%
}
.sa-checkin-hosts-grid table thead tr th:nth-of-type(7){
    width: 12%;
}
.sa-checkin-hosts-grid table tbody tr td:nth-of-type(7){
    width: 12%;
}
.sa-checkin-hosts-grid table thead tr th:nth-of-type(8){
    width: 12%;
}
.sa-checkin-hosts-grid table tbody tr td:nth-of-type(8){
    width: 12%;
}

.sa-checkin-licenses-grid table thead tr th:nth-of-type(1){
    width: 1%
}
.sa-checkin-licenses-grid table tbody tr td:nth-of-type(1){
    width: 1%
}
.sa-checkin-licenses-grid table thead tr th:nth-of-type(2){
    width: 15%
}
.sa-checkin-licenses-grid table tbody tr td:nth-of-type(2){
    width: 15%
}
.sa-checkin-licenses-grid table thead tr th:nth-of-type(4){
    width: 8%
}
.sa-checkin-licenses-grid table tbody tr td:nth-of-type(4){
    width: 8%
}
.sa-checkin-licenses-grid table thead tr th:nth-of-type(5){
    width: 12%
}
.sa-checkin-licenses-grid table tbody tr td:nth-of-type(5){
    width: 12%
}
.sa-checkin-licenses-grid table thead tr th:nth-of-type(6){
    width: 12%
}
.sa-checkin-licenses-grid table tbody tr td:nth-of-type(6){
    width: 12%
}

.sa-checkin-review-grid table thead tr th:nth-of-type(1){
    width: 15%
}
.sa-checkin-review-grid table tbody tr td:nth-of-type(1){
    width: 15%
}
.sa-checkin-review-grid table thead tr th:nth-of-type(2){
    width: 8%
}
.sa-checkin-review-grid table tbody tr td:nth-of-type(2){
    width: 8%
}
.sa-checkin-review-grid table thead tr th:nth-of-type(3){
    width: 15%
}
.sa-checkin-review-grid table tbody tr td:nth-of-type(3){
    width: 15%
}
.sa-checkin-review-grid table thead tr th:nth-of-type(5){
    width: 8%
}
.sa-checkin-review-grid table tbody tr td:nth-of-type(5){
    width: 8%
}
.sa-checkin-review-grid table thead tr th:nth-of-type(6){
    width: 12%
}
.sa-checkin-review-grid table tbody tr td:nth-of-type(6){
    width: 12%
}
.sa-checkin-review-grid table thead tr th:nth-of-type(7){
    width: 12%
}
.sa-checkin-review-grid table tbody tr td:nth-of-type(7){
    width: 12%
}