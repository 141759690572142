.header {
    padding-top: 2.625rem;
}

.section {
    margin-left: 2.5rem;
}

.section li {
    padding: 0.5rem 0rem;
}

.paragraph {
    margin-block: 1rem;
}

.indent {
    padding-inline-start: 0.75rem;
}

.img-container {
    text-align: center;
    display: block;
}

.top {
    margin-top: 0.4375rem;
}

.navgation-link {
    background-color: #e7e7e7;
    font-weight: 600;
}

.img-layout {
    max-width: 90%;
}

.hyperlink {
    color: blue;
    text-decoration: none;
    border-bottom: 1px solid blue;
}
