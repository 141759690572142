@media (max-width: 1365px) {
    html {
        font-size: 12px;
    }
}

@media (min-width: 1365px) {
    html {
        font-size: 14px;
    }
}

@media (min-width: 1550px) {
    html {
        font-size: 16px;
    }
}

body {
    margin: 0;
    min-width: 1280px;
}

:root {
    --keysight-red: #E90029;
    --keysight-dark-red: #871518;
    --keysight-blue: #30578D;
    --keysight-dark-blue: #071D49;
    --keysight-teal: #387D85;
    --keysight-purple: #823E81;
    --keysight-light-purple: #f7eef7;
    --keysight-dark-purple: #452144;
    --keysight-yellow: #F2A945;
    --keysight-slate-light: #F6F7F8;
    --keysight-slate-base: #80919F;
    --keysight-slate-dark: #121517;
    --keysight-gray-6: #333333;
    --keysight-gray-5: #403E44;
    --keysight-gray-4: #524F56;
    --keysight-gray-3: #D7D7D7;
    --keysight-gray-2: #E3DFE8;
    --keysight-gray-1: #F2F2F2;
    --color-surface-canvas: #FFFFFF;
    --color-surface-dark-primary: #121517;
    --color-surface-dark-secondary: #1F2528;
    --color-surface-light-primary: #F1F2F4;
    --color-surface-light-secondary: #EBEBEB;
    --color-surface-neutral-light: #F7F7F7;
    --color-surface-neutral-disabled: #666666;
    --color-surface-primary: #E90029;
    --color-surface-specialty-blue: #30578D;
    --color-surface-specialty-dark-blue: #071D49f;
    --color-text-primary: #262626;
    --color-text-prominent: #4D4D4D;
    --color-text-secondary: #666666;
    --color-text-disabled: #97999B;
    --color-text-tertiary: #D9D9D6;
    --color-text-link: #CC0025;
    --color-text-hover: #871518;
    --color-text-accent: #30578D;
    --color-text-highlight: #F2A945;
    --color-border-white: #FFFFFF;
    --color-border-primary: #E2E6E9;
    --color-border-secondary: #D9D9D6;
    --color-border-tertiary: #97999B;
    --color-border-invert: #121517;
    --color-border-active: #30578D;
    --color-alert-danger-light: #FAEBEB;
    --color-alert-danger-base: #C83737;
    --color-alert-danger-dark: #280B0B;
    --color-alert-warning-light: #FDF3E7;
    --color-alert-warning-base: #E67919;
    --color-alert-warning-dark: #613305;
    --color-alert-success-light: #EFF6F1;
    --color-alert-success-base: #468657;
    --color-alert-success-dark: #23432C;
    --keysight-pink: #FFC2C2;
    --keysight-primary: var(--keysight-red);
    --keysight-secondary: var(--keysight-blue);
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/*https://developers.google.com/fonts/docs/material_symbols#use_in_web*/
/*https://github.com/google/material-design-icons/blob/master/README.md#material-symbols--*/
/*https://marella.me/material-symbols/demo/*/
.material-symbols-rounded {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
}

.material-symbols.fill {
    font-variation-settings: 'FILL' 1
}

.material-symbols.small {
    font-size: 0.875rem;
}

.material-symbols.medium {
    font-size: 1.3rem;
}

.material-symbols.large {
    font-size: 1.5rem;
}

.material-symbols.xlarge {
    font-size: 2rem;
}

.material-symbols.xxlarge {
    font-size: 3rem;
}

.material-symbols.xxxlarge {
    font-size: 7rem;
}

.k-form, .k-form-inline {
    color: var(--color-text-primary);
}

.k-tabstrip > .k-content > .k-animation-container {
    width: 100%
}

.ksm-page-container {
    margin-left: 6.25rem;
    margin-right: 6.25rem;
    margin-bottom: 5rem;
    flex: 1;
}

.ksm-form-container {
    margin: auto;
    max-width: 53.125rem;
}

a {
    cursor: pointer;
    color: black;
    text-decoration: none;
}

.k-anchor {
    line-height: 1.625rem;
    color: var(--keysight-secondary);
    font-weight: 700;
    text-decoration: none;
}

.k-anchor:hover {
    border-bottom: 0.125rem solid var(--keysight-secondary);
}

hr {
    color: #AAAAAA;
}

.ksm-hr {
    height: 0.063rem;
    background-color: var(--color-border-primary);
    margin: 1rem 0;
}

.k-overlay {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    background-color: #2F4F4F;
    opacity: 0.6;
    overflow-x: hidden;
    transition: 0.5s;
}

.ksm-blod-blue-cell {
    color: var(--keysight-secondary);
    font-weight: bold !important;
}

.ksm-column-menu .k-button {
    border-radius: 0;
    font-size: 1rem;
}

.ksm-column-menu .k-button[type="reset"] {
    background-color: white;
    color: var(--keysight-primary);
    border-color: var(--keysight-primary);
}

.ksm-card {
    border-color: var(--color-border-primary);
    border-width: 1px;
    /*box-shadow: 0 0 4px 0 rgba(0, 0, 0, .1);*/
}

.ksm-anchor-button {
    line-height: 1.625rem;
    color: var(--keysight-secondary);
    font-size: 1rem;
    font-weight: 700;
    border: none;
    background: none;
    cursor: pointer;
    text-align: left;
}

.ksm-anchor-button:hover {
    text-decoration: underline;
    text-decoration-thickness: 0.125rem;
    text-underline-offset: 0.125rem;
}

.ksm-deactivate-button-old {
    cursor: none;
    pointer-events: none;
    color: #AAAAAA;
}

.ksm-default-header {
    color: white;
    background-color: var(--keysight-gray-4) !important;
    border-color: var(--keysight-gray-4) !important;
    margin-left: auto;
}

.ksm-default-header:hover {
    background-color: #524F56;
}

.ksm-deactivate-header {
    cursor: none;
    pointer-events: none;
    background-color: #524F56 !important;
}

.k-button-outline:disabled {
    border-color: var(--color-text-disabled);
    color: #FFFFFF;
    background-color: var(--color-text-disabled);
}

.k-button-outline-primary {
    color: var(--keysight-primary);
    border-color: var(--keysight-primary);
}

.k-button-icon {
    font-size: 1.375rem;
}

.k-icon-button > .k-svg-icon > svg {
    height: 1.3rem !important;
}


.k-h1,
.k-h2,
.k-h3,
.k-h4,
.k-h5 {
    color: var(--color-text-primary);
}

.k-text-nowrap {
    white-space: nowrap !important;
}


.k-header > .k-checkbox {
    border-color: #AAAAAA;
    background-color: var(--keysight-gray-4);
}

.k-header-desc {
    color: #424242;
    font-size: 0.875rem;
    font-style: italic;
}

.k-grid td {
    padding: 0.188rem 0.75rem;
}

.k-grid td > .k-checkbox {
    vertical-align: middle;
}

.ksm-grid-numeric-input > .k-input-inner {
    text-align: center;
}

.ksm-grid-icon {
    color: var(--keysight-secondary);
    font-size: 1.3rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.ksm-popover {
    border-radius: 0.55rem;
}

.ksm-popover > .k-child-animation-container > .k-popover {
    box-shadow: none !important;
}

.ksm-popover > .k-child-animation-container > .k-popover > .k-popover-inner > .k-popover-body {
    padding-block: var(--kendo-spacing-2, 1rem);
    padding-inline: var(--kendo-spacing-2, 1rem);
}

.ksm-popover-error > .k-child-animation-container > .k-popover {
    background-color: var(--color-alert-danger-light);
    border: 0.125rem solid var(--color-alert-danger-base);
    border-radius: 0.5rem;
}

.ksm-popover-error > .k-child-animation-container > .k-popover > .k-popover-callout {
    border: 0.125rem solid var(--color-alert-danger-base);
}

.ksm-popover-success > .k-child-animation-container > .k-popover {
    background-color: var(--color-alert-success-light);
    border: 0.125rem solid var(--color-alert-success-base);
    border-radius: 0.5rem;
}

.ksm-popover-success > .k-child-animation-container > .k-popover > .k-popover-callout {
    border: 0.125rem solid var(--color-alert-success-base);
}

.ksm-popover-warning > .k-child-animation-container > .k-popover {
    background-color: var(--color-alert-warning-light);
    border: 0.125rem solid var(--color-alert-warning-base);
    border-radius: 0.5rem;
}

.ksm-popover-warning > .k-child-animation-container > .k-popover > .k-popover-callout {
    border: 0.125rem solid var(--color-alert-warning-base);
}

.ksm-popover-alert-info > .k-child-animation-container > .k-popover {
    background-color: var(--keysight-light-purple);
    border: 0.125rem solid var(--keysight-purple);
    border-radius: 0.5rem;
}

.ksm-popover-alert-info > .k-child-animation-container > .k-popover > .k-popover-callout {
    border: 0.125rem solid var(--keysight-purple);
}

/*override kendo default no records styling*/
.k-grid-norecords-template {
    box-sizing: border-box;
    margin: 0 auto;
    width: 100% !important;
    height: 4em;
    border: none !important;
    line-height: 4em;
}

.k-selected td {
    background-color: var(--keysight-light-purple);
}

.k-pager-numbers .k-selected {
    border: 2px solid #426DA9;
}

.k-state-disabled .k-checkbox {
    border-color: #707070;
}

.k-state-disabled .k-checkbox {
    border-color: #707070;
}

.ksm-panelbar-default {
    border: none;
}

.ksm-panelbar-default > .k-panelbar-header {
    margin-bottom: 0.5rem;
}

.ksm-panelbar-default > .k-panelbar-header > .k-link {
    padding: 0.563rem 0.563rem;
}

.ksm-panelbar-default > .k-panelbar-header > .k-link > .k-panelbar-toggle {
    margin-inline-start: revert;
}

.ksm-panelbar-default > .k-panelbar-header > .k-link > .k-panelbar-item-text {
    width: 100%;
    order: 1;
}

.ksm-panelbar-no-arrow > .k-panelbar-header > .k-link > .k-icon {
    display: none;
}

.k-panelbar > .k-panelbar-header > .k-link.k-selected {
    cursor: pointer;
}

.k-panelbar > .k-panelbar-header > .k-link {
    cursor: pointer;
}

.ksm-panelbar-checkbox > .k-checkbox {
    border-color: white;
    background-color: var(--keysight-secondary);
}

.ksm-dialog > .k-window {
    border-color: #AAAAAA;
}

.ksm-dialog > .k-dialog {
    background: #FFFFFF;
    border-radius: 0.313rem;
}

.ksm-dialog > .k-dialog > .k-dialog-titlebar {
    background-color: white;
    border-radius: 0.313rem 0.313rem 0 0;
}

.ksm-dialog > .k-dialog > .k-dialog-titlebar > .k-dialog-title {
    color: var(--color-text-prominent);
    font-size: 1.5rem;
}

.ksm-dialog > .k-dialog > .k-dialog-titlebar > .k-dialog-actions > button > span {
    color: var(--keysight-secondary) !important;
}

.ksm-dialog > .k-dialog > .k-dialog-content {
    padding: 0.938rem 1.375rem 1.375rem 1.375rem;
}

.ksm-dialog > .k-dialog > .k-dialog-buttongroup {
    justify-content: center;
    padding: 1.313rem;
    gap: 0.938rem;
}

.k-upload .k-upload-button {
    border: none;
    background: none;
}

.ksm-upload > .k-dropzone > .k-dropzone-hint {
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    text-align: right;
    color: var(--keysight-secondary);
    margin-right: 1.25rem;
}

.ksm-upload > .k-dropzone > .k-upload-status {
    margin-right: 1.25rem
}

.ksm-upload .k-button-md {
    padding: 0;
    border: 0;
    border-radius: 0;
}

.ksm-dropdownlist > button > span {
    color: var(--keysight-secondary);
}

tbody > tr.k-master-row.disabled > td > button {
    color: grey !important;
}

tbody > tr.k-master-row.k-alt.disabled > td .k-icon {
    color: grey !important;
}

.k-detail-cell {
    padding: 0px 16px 0px 0px !important;
}

.k-detail-row, .k-detail-row.k-alt {
    background-color: var(--keysight-gray-3) !important;
}

.k-upload-status > button:first-child {
    display: none;
}

.k-input, .k-input-inner {
    border-color: var(--color-border-tertiary);
}

.k-input-solid:hover, .k-input-solid.k-hover {
    border-color: var(--color-border-tertiary);
}

.k-input-solid:focus, .k-input-solid.k-focus {
    border-color: var(--color-border-active);
    border-width: 2px;
    box-shadow: none;
}

/*used for grid qty input*/
.k-input-solid:focus-within {
    border-color: var(--color-border-active);
    border-width: 2px;
    box-shadow: none;
}

.k-input-solid.k-invalid, .k-input-solid.k-invalid:hover {
    border-width: 2px;
    border-color: var(--color-alert-danger-base);
}

.capital-letters {
    text-transform: lowercase;
}

.capital-letters:first-letter {
    text-transform: uppercase;
}

.ksm-hamburger .k-button-flat:focus::after, .k-button-flat.k-focus::after {
    opacity: 0 !important;
}

.ksm-hamburger-dropdown li.k-item {
    font-size: 1rem;
}

.ksm-hamburger-dropdown li.k-item:focus {
    box-shadow: none !important;
}

.ksm-hamburger-dropdown .k-link:hover {
    color: var(--keysight-secondary) !important;
    background-color: inherit !important;
}

.ksm-hamburger-dropdown .k-menu-link-text:hover {
    text-decoration-line: underline !important;
}

.ksm-hamburger-dropdown .k-disabled {
    color: var(--keysight-gray-6);
    opacity: 100;
}

.ksm-btngroup.k-selected {
    border-color: var(--keysight-primary);
    background-color: var(--keysight-primary);
}

.k-stepper .k-step-label .k-step-text {
    white-space: pre-wrap;
}

.k-grid-pager {
    border-top-width: 0;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-right-width: 1px;
    border-color: #AAAAAA;
    /*border: 0;*/
    font-size: 14px;
}

.k-grid-pager .k-pager-numbers .k-button {
    color: var(--kendo-color-secondary);
}

.k-list-item.k-selected, .k-selected.k-list-optionlabel {
    color: var(--kendo-color-on-secondary, white);
    background-color: var(--kendo-color-secondary);
}

.k-list-item.k-selected:hover,
.k-selected.k-list-optionlabel:hover,
.k-list-item.k-selected.k-hover,
.k-selected.k-hover.k-list-optionlabel {
    color: var(--kendo-color-on-secondary, white);
    background-color: var(--kendo-color-secondary-hover);
}

.k-sort-order {
    display: none;
}

.k-grid-header-menu .k-icon {
    color: white;
}

.k-table-thead {
    background-color: var(--color-text-prominent);
    color: white;
}

th .k-checkbox-wrap .k-checkbox {
    border-radius: var(--kendo-border-radius-sm, 0.125rem);
}

th .k-checkbox-wrap .k-checkbox:checked {
    border-color: #949393;
    color: white;
    background-color: var(--color-text-prominent);
    border-radius: var(--kendo-border-radius-sm, 0.125rem);
}

.k-table-th.k-sorted {
    background-color: var(--keysight-blue);
}

col.k-sorted {
    background-color: transparent !important;
}

.k-grid .k-table-th {
    border-bottom-width: 1px;
    border-color: #AAAAAA;
    border-left-width: 1px;
    border-top-width: 0;
}

.k-grid td, .k-grid .k-table-td {
    border-top-width: 0;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-color: #AAAAAA;
}

.k-grid {
    border-color: #AAAAAA;
}


.k-animation-container > .k-popup {
    box-shadow: 0.031rem 0.078rem 0.25rem 0 #aaaaaa;
}

textarea {
    resize: none;
}

.flex {
    display: flex;
    flex-direction: column;
}

.flex-grow-1 {
    flex-grow: 1;
}

.k-i-kpi-trend-equal:before {
    content: "\E015";
    color: black;
}