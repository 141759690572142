.select-deactivate-license-grid table thead tr th:nth-child(1) .k-cell-inner .k-link,
.select-deactivate-license-grid table thead tr th:nth-child(2) .k-cell-inner .k-link,
.select-deactivate-license-grid table thead tr th:nth-child(3) .k-cell-inner .k-link
.select-deactivate-license-grid table thead tr th:nth-child(4) .k-cell-inner .k-link
.select-deactivate-license-grid table thead tr th:nth-child(5) .k-cell-inner .k-link {
    cursor: default
}

.select-deactivate-license-grid table thead tr th:nth-of-type(1){
    width: 15%
}

.select-deactivate-license-grid table tbody tr td:nth-of-type(1){
    width: 15%
}

.select-deactivate-license-grid table thead tr th:nth-of-type(2){
    width: 10%
}

.select-deactivate-license-grid table tbody tr td:nth-of-type(2){
    width: 10%
}

.select-deactivate-license-grid table thead tr th:nth-of-type(4){
    width: 13.5%
}
.select-deactivate-license-grid table tbody tr td:nth-of-type(4){
    width: 13.5%
}

.select-deactivate-license-grid table thead tr th:nth-of-type(5){
    width: 10.5%
}
.select-deactivate-license-grid table tbody tr td:nth-of-type(5){
    width: 10.5%
}

.deactivate-keep-license-grid table thead tr th:nth-child(1) .k-cell-inner .k-link,
.deactivate-keep-license-grid table thead tr th:nth-child(2) .k-cell-inner .k-link,
.deactivate-keep-license-grid table thead tr th:nth-child(3) .k-cell-inner .k-link
.deactivate-keep-license-grid table thead tr th:nth-child(4) .k-cell-inner .k-link {
    cursor: default
}

.deactivate-keep-license-grid table thead tr th:nth-of-type(1){
    width: 15%
}

.deactivate-keep-license-grid table tbody tr td:nth-of-type(1){
    width: 15%
}

.deactivate-keep-license-grid table thead tr th:nth-of-type(2){
    width: 10%
}

.deactivate-keep-license-grid table tbody tr td:nth-of-type(2){
    width: 10%
}

.deactivate-keep-license-grid table thead tr th:nth-of-type(4){
    width: 10.5%
}
.deactivate-keep-license-grid table tbody tr td:nth-of-type(4){
    width: 10.5%
}