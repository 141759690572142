.renew-standalone-grid .k-grid-header col:nth-of-type(1){
    width: 9%
}
.renew-standalone-grid .k-grid-table col:nth-of-type(1){
    width: 9%
}

.renew-standalone-grid .k-grid-header col:nth-of-type(2){
    width: 9%
}
.renew-standalone-grid .k-grid-table col:nth-of-type(2){
    width: 9%
}

.renew-standalone-grid .k-grid-header col:nth-of-type(3){
    width: 79%
}
.renew-standalone-grid .k-grid-table col:nth-of-type(3){
    width: 79%
}

.renew-standalone-grid .k-grid-header col:nth-of-type(4){
    width: 3%
}
.renew-standalone-grid .k-grid-table col:nth-of-type(4){
    width: 3%
}

.renew-standalone-license-grid table thead tr th:nth-of-type(1){
    text-align: center;
}
.renew-standalone-license-grid table tbody tr td:nth-of-type(1){
    text-align: center;
}

.renew-standalone-license-grid table thead tr th:nth-of-type(2){
    width: 57%
}
.renew-standalone-license-grid table tbody tr td:nth-of-type(2){
    width: 57%
}

.renew-standalone-license-grid table thead tr th:nth-of-type(3){
    width: 9%
}
.renew-standalone-license-grid table tbody tr td:nth-of-type(3){
    width: 9%
}

.renew-standalone-license-grid table thead tr th:nth-of-type(4){
    width: 10%
}
.renew-standalone-license-grid table tbody tr td:nth-of-type(4){
    width: 10%
}

.renew-standalone-license-grid table thead tr th:nth-of-type(5){
    width: 12%
}
.renew-standalone-license-grid table tbody tr td:nth-of-type(5){
    width: 12%
}

.renew-standalone-license-grid table thead tr th:nth-of-type(6){
    width: 10%
}
.renew-standalone-license-grid table tbody tr td:nth-of-type(6){
    width: 10%
}


.renew-standalone-download-license-grid table thead tr th:nth-of-type(1){
    width: 60%
}
.renew-standalone-download-license-grid table tbody tr td:nth-of-type(1){
    width: 60%
}

.renew-standalone-download-license-grid table thead tr th:nth-of-type(2){
    width: 7%
}
.renew-standalone-download-license-grid table tbody tr td:nth-of-type(2){
    width: 7%
}

.renew-standalone-download-license-grid table thead tr th:nth-of-type(3){
    width: 9%
}
.renew-standalone-download-license-grid table tbody tr td:nth-of-type(3){
    width: 9%
}

.renew-standalone-download-license-grid table thead tr th:nth-of-type(4){
    width: 11%
}
.renew-standalone-download-license-grid table tbody tr td:nth-of-type(4){
    width: 11%
}

.renew-standalone-download-license-grid table thead tr th:nth-of-type(5){
    width: 11%
}
.renew-standalone-download-license-grid table tbody tr td:nth-of-type(5){
    width: 11%
}

.renew-standalone-download-license-grid table thead tr th:nth-of-type(6){
    width: 2%
}
.renew-standalone-download-license-grid table tbody tr td:nth-of-type(6){
    width: 2%
}

.order-number-cell:hover {
    cursor: pointer;
    border-bottom: 2px solid var(--keysight-primary);
}

.order-number-cell.disabled:hover {
    cursor: default;
    border-bottom: none;
}

.renew-standalone-license-grid table thead tr th input {
    display: none;
}

/** Used to set default cursor for Grid cols that cant be sorted **/
.renew-standalone-grid table thead tr th:nth-child(4) .k-cell-inner .k-link,
.renew-standalone-license-grid table thead tr th:nth-child(6) .k-cell-inner .k-link,
.renew-standalone-download-license-grid table thead tr th:nth-child(6) .k-cell-inner .k-link {
    cursor: default;
}

.renew-standalone-license-grid .partial .k-checkbox {
    border-color: var(--keysight-red);
}