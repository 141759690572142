.trial-license-host-subtitle {
    font-style: italic;
    font-weight: normal;
}

.trial-license-button {
    display: flex;
    justify-content: flex-end;
}

.trial-license-alerts {
    display: flex;
    justify-content: center;
}

.trial-license-alerts > div > div {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.trial-license-contact-us {
    display: inline;
    text-decoration: underline;
    cursor: pointer;
}

.trial-license-privacy-statement {
    align-self: center;
}

.contact-information-title {
    font-weight: normal;
    margin: 0;
    color: #424242;
}

.contact-information-label {
    display: flex;
    align-items: center;
}

.contact-information-modal-buttons {
    display: flex;
    justify-content: right;
    gap: 0.938rem;
}