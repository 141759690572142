.sa-report-grid {
    overflow-x: scroll;
    padding: 0;
}

.sa-report-grid .k-grid-header .k-header{
    vertical-align: middle;
}
.sa-report-grid tr {
    white-space: nowrap;
}

.sa-report-grid table thead tr th:nth-of-type(3){
    min-width: 30rem;
    white-space: normal;
}

.sa-report-grid table tbody tr td:nth-of-type(3){
    min-width: 30rem;
    white-space: normal;
}