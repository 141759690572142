.custom_info_tooltip {
    background: #E3DFE8;
    border: 2px solid #442667;
    border-radius: 5px;
    width: 750px;
  }
  
  .custom_info_tooltip > .k-popover-callout {
    background: #E3DFE8;
    border-color: #442667;
    border-width: 2px 2px 0 0;
  }

  .custom-icon-button:hover {
      text-decoration: none !important;
  }

  .view-by-host-grid table thead tr th:nth-child(4) .k-cell-inner .k-link {
    cursor: default;
  }

  .view-by-host-child-grid table thead tr th:nth-child(10) .k-cell-inner .k-link {
    cursor: default;
  }

  .view-by-product-grid table thead tr th:nth-child(8) .k-cell-inner .k-link {
    cursor: default;
  }

  .view-by-product-child-grid table thead tr th:nth-child(8) .k-cell-inner .k-link {
    cursor: default;
  }