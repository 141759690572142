/* Set column widths for my ea table */
.my-ea-grid  table thead tr th:nth-of-type(3){
    width: 12%
}
.my-ea-grid table tbody tr td:nth-of-type(3){
    width: 12%
}

.my-ea-grid  table thead tr th:nth-of-type(4){
    width: 12%
}
.my-ea-grid table tbody tr td:nth-of-type(4){
    width: 12%
}

.my-ea-grid  table thead tr th:nth-of-type(5){
    width: 8%
}
.my-ea-grid table tbody tr td:nth-of-type(5){
    width: 8%
}

.my-ea-grid  table thead tr th:nth-of-type(6){
    width: 8%
}
.my-ea-grid table tbody tr td:nth-of-type(6){
    width: 8%
}

.my-ea-grid  table thead tr th:nth-of-type(7){
    width: 8%
}
.my-ea-grid table tbody tr td:nth-of-type(7){
    width: 8%
}

.my-ea-grid  table thead tr th:nth-of-type(8){
    width: 12%
}
.my-ea-grid table tbody tr td:nth-of-type(8){
    width: 12%
}

.start-from-scratch-modal-text {
    margin-bottom: 1rem;
}

.ea-hamburger-indent {
    padding-left: 2.25rem;
}