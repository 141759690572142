// Define custom default variables
$keysight-red: #E90029;
$keysight-dark-red: #871518;
$keysight-blue: #30578D;
$keysight-dark-blue: #071D49;
$keysight-teal: #387D85;
$keysight-purple: #823E81;
$keysight-light-purple: #f7eef7;
$keysight-yellow: #F2A945;
$keysight-slate-light: #F6F7F8;
$keysight-slate-base: #80919F;
$keysight-slate-dark: #121517;
$keysight-gray-6: #333333;
$keysight-gray-5: #403E44;
$keysight-gray-4: #524F56;
$keysight-gray-3: #D7D7D7;
$keysight-gray-2: #E3DFE8;
$keysight-gray-1: #F2F2F2;
$color-surface-canvas: #FFFFFF;
$color-surface-dark-primary: #121517;
$color-surface-dark-secondary: #1F2528;
$color-surface-light-primary: #F1F2F4;
$color-surface-light-secondary: #EBEBEB;
$color-surface-neutral-light: #F7F7F7;
$color-surface-neutral-disabled: #666666;
$color-surface-primary: #E90029;
$color-surface-specialty-blue: #30578D;
$color-surface-specialty-dark-blue: #071D49;
$color-text-primary: #262626;
$color-text-prominent: #4D4D4D;
$color-text-secondary: #666666;
$color-text-disabled: #97999B;
$color-text-tertiary: #D9D9D6;
$color-text-link: #CC0025;
$color-text-hover: #871518;
$color-text-accent: #30578D;
$color-text-highlight: #F2A945;
$color-border-white: #FFFFFF;
$color-border-primary: #E2E6E9;
$color-border-secondary: #D9D9D6;
$color-border-tertiary: #97999B;
$color-border-invert: #121517;
$color-border-active: #30578D;
$color-alert-danger-light: #FAEBEB;
$color-alert-danger-base: #C83737;
$color-alert-danger-dark: #280B0B;
$color-alert-warning-light: #FDF3E7;
$color-alert-warning-base: #E67919;
$color-alert-warning-dark: #613305;
$color-alert-success-light: #EFF6F1;
$color-alert-success-base: #468657;
$color-alert-success-dark: #23432C;
$keysight-pink: #FFC2C2;
$keysight-primary: $keysight-red;
$keysight-secondary: $keysight-blue;
$keysight-alternate: $color-text-prominent;

$white: #FFFFFF;
$black: #000000;

$ksm-blue: #426DA9;
$color-text-prominent: #4D4E4D;
$ksm-teal: #3A828A;
$ksm-purple: #823E81;
$ksm-error: #E90029;
$ksm-warning: #FCB300;
$ksm-success: #239706;

$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;

// Import core functions
@import '../../../node_modules/@progress/kendo-theme-core/scss/functions/index.import.scss';

// Change Kendo base colors
$kendo-colors: ();
$kendo-colors: k-map-merge(
    $kendo-colors,
    k-generate-color-variations('primary', $keysight-primary, 'default'),
    k-generate-color-variations('secondary', $keysight-secondary, 'default'),
    k-generate-color-variations('tertiary', $keysight-alternate, 'default'),
    k-generate-color-variations('error', $color-alert-danger-base, 'default'),
);

// Text
$kendo-component-text: $color-text-primary;

// Headings
$kendo-h1-font-family: inherit;
$kendo-h2-font-family: inherit;
$kendo-h3-font-family: inherit;
$kendo-h4-font-family: inherit;
$kendo-h5-font-family: inherit;

$kendo-h1-font-size: 3rem !default;
$kendo-h2-font-size: 2.5rem !default;
$kendo-h3-font-size: 2rem !default;
$kendo-h4-font-size: 1.5rem !default;
$kendo-h5-font-size: 1.25rem !default;

$kendo-h1-line-height: 3.75rem !default;
$kendo-h2-line-height: 3.125rem !default;
$kendo-h3-line-height: 2.5rem !default;
$kendo-h4-line-height: 1.875rem !default;
$kendo-h5-line-height: 1.875rem !default;

$kendo-h1-font-weight: $font-weight-normal !default;
$kendo-h2-font-weight: $font-weight-normal !default;
$kendo-h3-font-weight: $font-weight-semibold !default;
$kendo-h4-font-weight: $font-weight-semibold !default;
$kendo-h5-font-weight: $font-weight-semibold !default;

$kendo-h1-letter-spacing: -0.063rem !default;
$kendo-h2-letter-spacing: -0.063rem !default;
$kendo-h3-letter-spacing: -0.063rem !default;
$kendo-h4-letter-spacing: -0.063rem !default;
$kendo-h5-letter-spacing: -0.063rem !default;

$kendo-h2-margin: 0 0 0.5rem !default;
$kendo-h3-margin: 0 0 0.5rem !default;
$kendo-h5-margin: 0 0 0.5rem !default;

// Buttons
$kendo-button-lg-padding-x: 1rem !default;
$kendo-button-lg-padding-y: 0.375rem !default;

$kendo-button-disabled-bg: $color-text-disabled !default;
$kendo-button-disabled-border: $color-text-disabled !default;
$kendo-button-disabled-text: $white !default;

// Grid
$kendo-grid-header-bg: $keysight-gray-6 !default;
$kendo-grid-text: $keysight-gray-6 !default;
$kendo-grid-header-text: $keysight-gray-4 !default;
$kendo-grid-selected-bg: $keysight-light-purple !default;

// Grid - Pager
$kendo-pager-border-width: 0 !default;
$kendo-pager-focus-shadow: none;

// Lists
$kendo-list-item-selected-bg: $keysight-secondary !default;

// Checkbox
$checkbox-color: $keysight-secondary;
$kendo-checkbox-border: #aaaaaa !default;
$kendo-checkbox-checked-bg: $checkbox-color !default;
$kendo-checkbox-focus-checked-shadow: $checkbox-color !default;

// Switch
$kendo-switch-on-track-bg: $keysight-secondary !default;

// Panel bar
$panelbar-color: $keysight-secondary;
$kendo-panelbar-header-bg:  $panelbar-color !default;
$kendo-panelbar-header-hover-bg:  $panelbar-color !default;
$kendo-panelbar-header-selected-bg:  $panelbar-color !default;
$kendo-panelbar-header-selected-hover-bg:  $panelbar-color !default;
$kendo-panelbar-text: $white !default;
$kendo-panelbar-header-text: $white !default;

// Stepper
$stepper-color: $keysight-secondary;
$kendo-stepper-indicator-current-bg: $stepper-color !default;
$kendo-stepper-indicator-current-border: $stepper-color !default;
$kendo-stepper-indicator-current-hover-bg: $stepper-color !default;
$kendo-stepper-indicator-done-bg: $stepper-color !default;
$kendo-stepper-indicator-done-text: $white !default;
$kendo-stepper-indicator-done-hover-bg: $stepper-color !default;
$kendo-stepper-progressbar-fill-bg: $stepper-color !default;
$kendo-stepper-indicator-done-disabled-bg: color-mix(in srgb,  $stepper-color 60%, transparent) !default;

// Menu
$menu-color: $color-text-primary;
$kendo-menu-item-text: $menu-color !default;
$kendo-menu-item-hover-text: $menu-color !default;

// Import entire kendo theme default
@import "../../../node_modules/@progress/kendo-theme-default/dist/all.scss";

// To compile this file
// Move into kendo-custom-theme directory and run:
// sass kendo-custom-theme.scss kendo-custom-theme.css