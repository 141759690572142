.renew-nemo-grid .k-grid-header col:nth-of-type(1){
    width: 10%
}
.renew-nemo-grid .k-grid-table col:nth-of-type(1){
    width: 10%
}

.renew-nemo-grid .k-grid-header col:nth-of-type(2){
    width: 70%
}
.renew-nemo-grid .k-grid-table col:nth-of-type(2){
    width: 70%
}

.renew-nemo-grid .k-grid-header col:nth-of-type(3){
    width: 10%
}
.renew-nemo-grid .k-grid-table col:nth-of-type(3){
    width: 10%
}

.renew-nemo-grid .k-grid-header col:nth-of-type(4){
    width: 10%
}
.renew-nemo-grid .k-grid-table col:nth-of-type(4){
    width: 10%
}

/** Used to set default cursor for Grid cols that cant be sorted **/
.renew-nemo-grid table thead tr th:nth-child(2) .k-cell-inner .k-link,
.renew-nemo-grid table thead tr th:nth-child(3) .k-cell-inner .k-link,
.renew-nemo-grid table thead tr th:nth-child(4) .k-cell-inner .k-link {
    cursor: default;
}