.search-products-grid table thead tr th:nth-of-type(3){
    width: 9%
}
.search-products-grid table tbody tr td:nth-of-type(3){
    width: 9%
}
.search-products-grid table thead tr th:nth-of-type(4){
    width: 1%
}
.search-products-grid table tbody tr td:nth-of-type(4){
    width: 1%
}

.cart-products-grid table thead tr th:nth-of-type(3){
    width: 1%
}
.cart-products-grid table tbody tr td:nth-of-type(3){
    width: 1%
}

.unassigned-checkout-grid table thead tr th:nth-of-type(1){
    width: 1%
}
.unassigned-checkout-grid table tbody tr td:nth-of-type(1){
    width: 1%
}
.unassigned-checkout-grid table thead tr th:nth-of-type(4){
    width: 10%
}
.unassigned-checkout-grid table tbody tr td:nth-of-type(4){
    width: 10%
}
.unassigned-checkout-grid table thead tr th:nth-of-type(5){
    width: 8%
}
.unassigned-checkout-grid table tbody tr td:nth-of-type(5){
    width: 8%
}

.client-info-grid table thead tr th:nth-child(1) .k-cell-inner .k-link,
.client-info-grid table thead tr th:nth-child(2) .k-cell-inner .k-link,
.client-info-grid table thead tr th:nth-child(3) .k-cell-inner .k-link,
.client-info-grid table thead tr th:nth-child(4) .k-cell-inner .k-link {
    cursor: default
}

.client-info-grid table thead tr th:nth-of-type(1){
    width: 40%
}

.client-info-grid table tbody tr td:nth-of-type(1){
    width: 40%
}

.client-info-grid table thead tr th:nth-of-type(2){
    width: 15%
}

.client-info-grid table tbody tr td:nth-of-type(2){
    width: 15%
}

.client-info-grid table thead tr th:nth-of-type(3){
    width: 15%
}

.client-info-grid table tbody tr td:nth-of-type(3){
    width: 15%
}

.client-info-grid table thead tr th:nth-of-type(4){
    width: 30%
}

.client-info-grid table tbody tr td:nth-of-type(4){
    width: 30%
}

.assign-host-grid table thead tr th:nth-child(1) .k-cell-inner .k-link,
.assign-host-grid table thead tr th:nth-child(2) .k-cell-inner .k-link,
.assign-host-grid table thead tr th:nth-child(3) .k-cell-inner .k-link {
    cursor: default
}

.assign-host-grid table thead tr th:nth-of-type(1){
    width: 13%
}

.assign-host-grid table tbody tr td:nth-of-type(1){
    width: 13%
}

.assign-host-grid table thead tr th:nth-of-type(3){
    width: 8%
}

.assign-host-grid table tbody tr td:nth-of-type(3){
    width: 8%
}


.sa-checkout-assign-products-grid table thead tr th:nth-of-type(1){
    width: 25%
}

.sa-checkout-assign-products-grid table tbody tr td:nth-of-type(1){
    width: 25%
}
.sa-checkout-assign-products-grid table thead tr th:nth-of-type(3){
    width: 12%
}

.sa-checkout-assign-products-grid table tbody tr td:nth-of-type(3){
    width: 12%
}
