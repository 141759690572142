.ea-dashboard-h-50 {
    height: 50vh;
}

.ea-dashboard-card-border {
    border: 1px solid var(--keysight-gray-4);
}

.ea-dashboard-card-title {
    display: flex;
    justify-content: center;
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0;
}

.ea-dashboard-chart-margin {
    margin-left: 5rem;
    margin-right: 5rem;
}

.ea-dashboard-no-wrap {
    white-space: nowrap;
}